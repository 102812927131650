<!--
 * @Autor: 符应飞
 * @Date: 2021-10-30 11:29:54
 * @LastEditors: 符应飞
 * @Description: 登录页面
 * @LastEditTime: 2021-10-30 11:29:54
-->
<template>
  <div class="login-page">
   
    <van-nav-bar
        title="登录"
        :fixed="true"
      />

    <div class="form-template">
      
      <div class="logo-img">
        <van-image
          round 
          width="2.666667rem"
          height="2.666667rem"
          fit="cover"
          :src="logoImg"
        />
      </div>

      <van-form ref="loginForm" @submit="onSubmit">
        <van-field
          v-model="form.username"
          label-width="50"
          name="username"
          label="用户名"
          placeholder="请输入用户名"
          :rules="rules.username"
        />
        <van-field
         v-model="form.password"
          label-width="50"
          type="password"
          name="password"
          label="密码"
          placeholder="请输入密码"
          :rules="rules.password"
        />
        <div style="margin: 40px 16px;">
          <van-button round block type="info" native-type="submit" :loading="loading"  loading-text="登录中...">登录</van-button>
        </div>
      </van-form>

    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
import throttle from "@/utils/throttle"
import {mapState} from 'vuex'
 // 校验用户名，密码
function asyncValidator(val){
  return val? true : false;
}
function asyncValidatorPassword(val){
  return val? true : false;
}
export default {
  
  data() {
    return {
      params: {
        title: "登录",
      },
      form: {
        username: "",
        password: "",
      },
      logoImg:require('@/assets/logo.png'),
      rules:{
          username:[ { required: true,validator: asyncValidator}],
          password:[{ required: true,validator:asyncValidatorPassword}]
      },
      redirectPath:'',//需要重定向的路径
      loading:false,//加载状态
    };
  },
   computed:{
    ...mapState(['groupId'])
  },
  created(){
    //需要重定向的路径
    if(this.$route.query.redirect){
      this.redirectPath = this.$route.query.redirect
    }else{
      this.redirectPath = '/'
    }
  },
  methods: {
  
    onSubmit: function (e) {
      let {password,username} = e;
      if(!password || !username) return;
      let loginForm = {password,mobile:username,groupId:this.groupId}
      
      // 开启加载状态
      this.loading = true 
      this.$store.dispatch('login',loginForm).then((token) => {
        
         Toast('登录成功');
              setTimeout(() => {
                // 跳转到对应的页面this.redirect || 
                console.log(this.$store.state.token,'0000000000')
                  this.$router.push({ path: "/" });
                  this.loading = false;
              }, 500);
        }).catch((err) => {
          this.loading = false;
          Toast(err.msg)
        })
      },
  },
};
</script>

<style lang="less">
.login-page {
  width: 100%;
  height: 100vh;
  padding: 0 20px;
  box-sizing: border-box;
  .logo-img{
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .form-template {
    // width: 100%;
    // height: calc(100vh - 46px);
    box-sizing: border-box;
    padding-top: 100px;
    .van-cell {
      border-bottom: 1px solid #eee;
     
    }
    .submit-btn {
      margin: 60px 16px;
    }
    .van-field__body{
        padding-left: 10px;
    }
    .van-image{
        width: 100px;
        height: 37px;
    }
    .code-field{
       border-bottom: 1px solid #eee;
       .van-cell{
           border-bottom: none;
       }
       
    }
  }
}
.load-status{
  position: fixed;
  left: 50%;
  top: 46px;
  transform: translateX(-50%);

}
</style>